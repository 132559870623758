import React from 'react'
import './destinationCard.css'

function PopularLocationCard(props) {
    const [deletePrompt,setDeletePrompt]=React.useState(false)
    const [password,setPassword]=React.useState('');
    return (
      <div className='card'>
        <img className='img-card' src={props.site.titlePicture} alt=''/>
        <p className='text'>{props.site.title}</p>
        <div className='button-container'>
            <button className='button' onClick={()=>setDeletePrompt(true)}>Delete</button>
        </div>
        <form style={{display: deletePrompt?'block':'none'}} className='form' 
            onSubmit={(e)=>{props.handleDelete(e,props.site.id,password)}}>
            <input onChange={(e)=>{setPassword(e.target.value)}} value={password} type='password' placeholder='enter password'></input>
            <button type='submit'>Go</button>
            <button onClick={(e)=>{
                e.preventDefault();
                setDeletePrompt(false)}}>X</button>
        </form>
      </div>
    );
  }

  export default PopularLocationCard