import React from 'react';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
import './Footer.css';

function Footer() {
  return (
    <>
      <div className='row'>
        <p className='para1'>About Us</p>
        <p className='para1'>Disclaimer</p>
        <p className='para1'>Terms and Conditions</p>
      </div>
      <div className='row'>
        <p className='low-text'>© 2022 pakexplore All rights reserved</p>
        <p className='low-text'>Developed my Dr. Mumtaz Ali</p>
      </div>
    </>
  );
}

export default Footer;
