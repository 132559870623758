import React from 'react';
import CarouselComponent from './Carousel';
import HomePageHeader from './HomePageHeader';
import MapComponent from './MapComponent';
import SpotDescription from './SpotDescription';
import './spotPage.css'
import YoutubeComponent from './YTComponent';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';


function SpotPage(){
    const destination=useLocation().state
    React.useEffect(() => {
        AOS.init()
    }, [])
    const styles={
        mapStyle: {
            border: '2px solid darkturquoise',
            width: '33%',
            marginRight: '10px',
            height: '99%',
        },
        streetviewStyle: {
            border: '2px solid darkturquoise',
            width: '65%',
            marginRight: '10px',
            height: '99%',
        },
        addMidStyle: {
            width: '15%',
            height: '100%',
        },
    }
    const pictures=destination.pictures.map(pic=>{
        return {src: pic}
    })
    console.log(destination.locationAccess)
    return (
        <>
            <div className='main-container'>
                <HomePageHeader />
                <div data-aos='zoom-in'><CarouselComponent height={'600px'} data={pictures}/></div>
                <div className='details-container'>
                    <SpotDescription description={destination.description} title={destination.title}
                        stayOptions={destination.stayOptions} locationAccess={destination.locationAccess} activities={destination.activities} />
                </div>
                <div data-aos="fade-up" data-aos-duration='1000' data-aos-anchor-placement='center-bottom' className='map-container'>
                    <MapComponent mapview={true} styling={styles.mapStyle} lat={destination.latitude} lon={destination.longitude}/>
                    {destination.displayStreetview && <MapComponent styling={styles.streetviewStyle} streetViewSrc={destination.streetViewSrc}/>}
                </div>
                {destination.youtubeEmbed &&<div data-aos="fade-up" data-aos-duration='1000' data-aos-anchor-placement='center-bottom' className='youtube-container'>
                    <YoutubeComponent frame={destination.youtubeEmbed}/>
                </div>}
            </div>
            <Footer/>
        </>
    )
}

export default SpotPage;