import React from "react";
import axios from 'axios'
import './forms.css'

class PostPopularPlaces extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            title: '',
            picture: '',
            link: '',
        }
        this.url=props.url
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleChange=this.handleChange.bind(this);
    }

    handleChange(e){
        e.preventDefault()
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    
    async handleSubmit(e){
        e.preventDefault()
        try{
            
            await axios.post(this.url+'popularlocations/',this.state)
            alert('data saved');
            this.props.setPurpose('Popular Places');
        }catch(err){
            alert('something went wrong!')
            console.log(err);
        }
    }

    render() {
        const {title,picture,link} = this.state;
        return (
            <div>
                <form  onSubmit={this.handleSubmit}>
                    <label className="label">title</label>
                    <input className='input' value={title} type='text' placeholder='enter title' name='title' onChange={this.handleChange} required></input>
                    <label className="label">picture</label>
                    <input className='input' value={picture} type='text' placeholder='enter picture link' name='picture' onChange={this.handleChange} required></input>
                    <label className="label">spot link</label>
                    <input className='input' value={link} type='text' placeholder='enter spot link' name='link' onChange={this.handleChange} required></input>
                    <button className="button" type='submit'>Post Add</button>
                </form>
            </div>
        )
    }
}

export default PostPopularPlaces