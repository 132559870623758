import React from 'react'
import axios from 'axios'
import DestinationCard from './destinationCard'
import Alert from './alert-msg'
import ModifyForm from './modify-form'

function ControlPanel(props){
    const [loading,setLoading]=React.useState(true)
    const [destinations,setDestinations]=React.useState({})
    const [displayAlert,setDisplayAlert]=React.useState('none')
    const [modifySiteName,setModifySiteName]=React.useState(null)
    const [showModifyForm,setShowModifyForm]=React.useState(false)
    const styles={
        headers: {
            textAlign: 'center'
        }
    }    
    React.useEffect(()=>{
        setLoading(true)
        axios.get(props.url).then((data)=>{
            let sortedDestinations={}
            for(let i=0;i<data.data.length;i++){
                const dist=data.data[i]
                if(sortedDestinations[dist.province]){
                    if(sortedDestinations[dist.province][dist.district]){
                        sortedDestinations[dist.province][dist.district].push(dist)
                    }else{
                        sortedDestinations[dist.province][dist.district]=[dist]
                    }
                }else{
                    sortedDestinations[dist.province]={}
                    sortedDestinations[dist.province][dist.district]=[dist]
                }
            }
            setLoading(false)
            setDestinations(sortedDestinations)
        }).catch((err)=>{
            console.log(err)
        })
    },[])
    const handleDelete=(e,id,password,province,district)=>{
        e.preventDefault()
        const url=props.url+id
        axios.delete(url,{data: {password}}).then((response)=>{
            if(response.data.operationSuccessful){
                let modifiedDestination=Object.assign(destinations)
                modifiedDestination[province][district]=modifiedDestination[province][district].filter((dist)=>dist.id!==id)
                if(modifiedDestination[province][district].length===0)
                    delete modifiedDestination[province][district]
                if(modifiedDestination[province].length===0)
                    delete modifiedDestination[province]
                setDestinations({...modifiedDestination})
            }
        }).catch((err=>{
            if(err.response.data==='un-authorized')
            {
                setDisplayAlert('block')
                setTimeout(()=>{setDisplayAlert('none')},5000)
            }
            console.log(err)
        }))
    }
    const handleModify=(site)=>{
        setShowModifyForm(true)
        setModifySiteName({...site})
    }

    const handleModifySubmit=async (e,site,id)=>{
        e.preventDefault()
        const url=props.url+id
        try{
            let response= await axios.put(url,site)
            if(response.data.updateSuccessful){
                let modifiedDestination=Object.assign(destinations)
                let temp=modifiedDestination[site.province][site.district]
                let ind=temp.findIndex((p)=>p.id===id)
                temp[ind]={id,...site};
                setDestinations({...modifiedDestination})
                setShowModifyForm(false)
                setModifySiteName({})
            }
            alert('data updated');
        }catch(err){
            console.log(err);
        }
    }
    const cards=React.useMemo(()=>{
        let cards=[]
        for(const province in destinations){
            cards.push(<div key={province} style={styles.headers}><h2>{province}</h2><hr></hr></div>)
            for(const district in destinations[province]){
                cards.push(<div key={district}><h3>{district}</h3><hr></hr></div>)
                const dist=destinations[province][district]
                const l=dist.length
                for(let i=0;i<l;i++){
                    cards.push(<DestinationCard key={dist[i].id} site={dist[i]}
                    handleDelete={handleDelete} handleModify={handleModify}/>)
                }
            }
        }
        return cards
    },[destinations,styles.headers]) 
    return <>
        <Alert displayAlert={displayAlert}/>
        {showModifyForm?<ModifyForm url={props.url} {...modifySiteName} handleModifySubmit={handleModifySubmit}/>:loading?<>loading</>:cards}
        {showModifyForm &&  <button className='back-button' onClick={()=>{setShowModifyForm(false)}}>go back</button>}
    </>
}

export default ControlPanel