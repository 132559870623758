import React from 'react'
import axios from 'axios'
import PopularLocationCard from './popularLocationCard'
import Alert from './alert-msg'

function PopularPlaces(props){
    const [loading,setLoading]=React.useState(true)
    const [locations,setLocations]=React.useState([])
    const [displayAlert,setDisplayAlert]=React.useState('none')
      
    React.useEffect(()=>{
        setLoading(true)
        axios.get(props.url+'popularlocations/').then((data)=>{
            setLoading(false)
            setLocations(data.data)
        }).catch((err)=>{
            console.log(err)
        })
    },[])
    const handleDelete=(e,id,password)=>{
        e.preventDefault()
        const url=props.url+'popularlocations/'+id
        axios.delete(url,{data: {password}}).then((response)=>{
            if(response.data.operationSuccessful){
                let modifiedLocations=[...locations]
                modifiedLocations=modifiedLocations.filter((dist)=>dist.id!==id)
                setLocations(modifiedLocations)
            }
        }).catch((err=>{
            if(err.response.data==='un-authorized')
            {
                setDisplayAlert('block')
                setTimeout(()=>{setDisplayAlert('none')},5000)
            }
            console.log(err)
        }))
    }
    const cards=locations.map((loc,ind)=>(
        <PopularLocationCard key={ind} site={{id: loc.id, titlePicture: loc.picture, title: loc.title}} handleDelete={handleDelete}/>
    ))
    return <>
        <Alert displayAlert={displayAlert}/>
        {loading?<>loading</>:cards}
    </>
}

export default PopularPlaces