import React from "react";
import axios from 'axios'
import './forms.css'

class PostForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            category: '',
            title: '',
            province: '',
            district: '',
            description: '',
            locationAccess: '',
            activities: '',
            stayOptions: '',
            youtubeEmbed: '',
            latitude: '',
            longitude: '',
            streetViewSrc: '',
            displayStreetview: false,
            titlePicture: '',
            pictures: [],
        }
        this.url=props.url
        this.districtsData=[];
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleLinksChange=this.handleLinksChange.bind(this);
    }

    async componentDidMount(){
        try{
            let response=await axios.get(this.url+'data')
            this.districtsData=response.data;
            this.setState(this.state)
        }catch(err){
            console.log(err)
        }
    }

    handleChange(e){
        e.preventDefault()
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleLinksChange(e){
        e.preventDefault()
        this.setState({
            [e.target.name]: e.target.value.split('\n')
        });
    }

    // handlePicturesChange(e){
    //     e.preventDefault()
    //     const {pictures}=this.state;
    //     pictures[e.target.name]=e.target.value;
    //     this.setState({
    //         pictures
    //     });
    // }

    // addPictures(e){
    //     e.preventDefault();
    //     const {pictures}=this.state;
    //     pictures.push('');
    //     this.setState({pictures})
    // }

    // removePictures(e){
    //     e.preventDefault();
    //     const {pictures}=this.state;
    //     pictures.pop();
    //     this.setState({pictures})
    // }
    
    async handleSubmit(e){
        e.preventDefault()
        try{
            
            await axios.post(this.url+'new/',this.state)
            alert('data saved');
            this.props.setPurpose('Manage Places');
        }catch(err){
            alert('something went wrong!')
            console.log(err);
        }
    }
    selectDistricts(){
        let districts={}
        const options=[<option key='epmty' className="option" value=''>--Please choose district--</option>]
        switch(this.state.province){
            case 'gilgit baltistan': districts=this.districtsData.gb
            break;
            case 'khyber pakhtunkhwa': districts=this.districtsData.kp
            break;
            case 'punjab': districts=this.districtsData.pjb
            break;
            case 'islamabad': districts=this.districtsData.isb
            break;
            case 'balochistan': districts=this.districtsData.bln
            break;
            case 'sindh': districts=this.districtsData.snd
            break;
            case 'azad jammu & kashmir': districts=this.districtsData.ajk
            break;
            default: break            
        }
        for(var key in districts){
            options.push(<option key={key} className="option" value={districts[key].name}>{districts[key].name}</option>)
        }
        return options
    }

    render() {
        const districtSelect=this.selectDistricts()
        const {category,title,province,district,description,locationAccess,activities,stayOptions,youtubeEmbed,
            latitude,longitude,streetViewSrc,displayStreetview,titlePicture} = this.state;
        //const surl='https://www.google.com/maps/embed/v1/streetview?key=AIzaSyBohem4NYAiffFJlxdr09zWiz5iHT9av6E&location='+latitude+','+longitude+'&heading=210&pitch=10&fov=35'
        // const picturesList=pictures.map((item,ind)=>{
        //     return (
        //         <input className='input' value={item} type='text' placeholder='enter picture url' name={ind} key={ind} onChange={this.handlePicturesChange} required></input>
        //     )
        // });
        return (
            <div>
                <form  onSubmit={this.handleSubmit}>
                <label className="label">category</label>
                    <select className="input" name='category' value={category} onChange={this.handleChange}>
                        <option value=''>--Please choose category--</option>
                        <option value='restaurant'>Restaurant</option>
                        <option value='heritage and culture'>Heritage and Culture</option>
                        <option value='natural scenery'>Natural Scenery</option>
                        <option value='food street / area'>Food Street / Area</option>
                        <option value='commercial'>Commercial</option>
                    </select>
                    <label className="label" >title</label>
                    <input className='input' value={title} type='text' placeholder='enter title' name='title' onChange={this.handleChange} required></input>
                    <label className="label">province</label>
                    <select className="input" name='province' value={province} onChange={this.handleChange}>
                        <option value=''>--Please choose province--</option>
                        <option value='gilgit baltistan'>Gilgit Baltistan</option>
                        <option value='khyber pakhtunkhwa'>Khyber Pakhtunkhwa</option>
                        <option value='punjab'>Punjab</option>
                        <option value='azad jammu & kashmir'>Azad Jammu & Kashmir</option>
                        <option value='balochistan'>Balochistan</option>
                        <option value='sindh'>Sindh</option>
                        <option value='islamabad'>Islamabad</option>
                    </select>
                    <label className="label">district</label>
                    <select className="input" name='district' value={district} onChange={this.handleChange}>
                        {districtSelect}
                    </select>
                    <label className="label">description</label>
                    <textarea
                        name='description'
                        value={description}
                        placeholder="description"
                        className="input"
                        rows={10}
                        onChange={this.handleChange}
                    />
                    <label className="label">locationAccess</label>
                    <textarea
                        name='locationAccess'
                        value={locationAccess}
                        placeholder="locationAccess"
                        className="input"
                        rows={10}
                        onChange={this.handleChange}
                    />
                    <label className="label">activities</label>
                    <textarea
                        name='activities'
                        value={activities}
                        placeholder="activities"
                        className="input"
                        rows={10}
                        onChange={this.handleChange}
                    />
                    <label className="label">stayOptions</label>
                    <textarea
                        name='stayOptions'
                        value={stayOptions}
                        placeholder="stayOptions"
                        className="input"
                        rows={10}
                        onChange={this.handleChange}
                    />
                    <label className="label">youtubeEmbed</label>
                    <input className='input' value={youtubeEmbed} type='text' placeholder='youtubeEmbed' name='youtubeEmbed' onChange={this.handleChange}></input>
                    <label className="label">latitude</label>
                    <input className='input' value={latitude} type='number' placeholder='latitude' name='latitude' onChange={this.handleChange} required></input>
                    <label className="label">longitude</label>
                    <input className='input' value={longitude} type='number' placeholder='longitude' name='longitude' onChange={this.handleChange} required></input>
                    <label className="label">street view source</label>
                    <input className='input' value={streetViewSrc} type='text' placeholder='street view source' name='streetViewSrc' onChange={this.handleChange}></input>
                    <label className="label">display streetview</label>
                    <select className="input" name='displayStreetview' value={displayStreetview} onChange={this.handleChange}>
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                    </select>
                    <label className="label">titlePicture</label>
                    <input className='input' value={titlePicture} type='text' placeholder='Title Picture' name='titlePicture' onChange={this.handleChange} required></input>
                    <label className="label">Picutres</label>
                    <textarea
                        name='pictures'
                        placeholder="enter links"
                        className="input"
                        rows={10}
                        onChange={this.handleLinksChange}
                    />
                    <button className="button" type='submit'>Post Add</button>
                </form>
                <div className="check-streetview">
                    <iframe
                        width="600"
                        height="450"
                        title="location streetview"
                        className="map-streetview"
                        loading="lazy"
                        frameBorder="0" 
                        referrerPolicy="no-referrer-when-downgrade"
                        allowFullScreen
                        src={streetViewSrc}>
                    </iframe>
                </div>
            </div>
        )
    }
}

export default PostForm