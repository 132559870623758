import { useNavigate } from 'react-router-dom'
import {ReactComponent as Logo} from '../images/logo-black.svg'
export default function HomePageHeader(){
    const navigate=useNavigate();
    const styles={
        head:{
            fontSize: 'large',
            fontWeight: 'bold',
            height: '50px',
            margin: '10px 0px 10px 0'
        },
        hr: {
            color: '#ebecf0',
        },
        container: {
            maxWidth: '1000px',
            margin: 'auto',
            lineHeight: '50px'
        },
        logo: {
            height: '56px',
            marginLeft: '4px',
            cursor: 'pointer'
        }
    }
    return (
        <>
            <div style={styles.head}>
                    <Logo onClick={()=>{
                        navigate('/')
                    }} style={styles.logo} />
            </div>
        </>
    )
}