import './App.css';
import {useState,useEffect} from 'react'
import PostForm from './components/post-form'
import ControlPanel from './components/control-panel'
import axios from 'axios'
import PostPopularPlaces from './components/postPopularPlaces';
import PopularPlaces from './components/popularPlaces';

function App() {
  const [purpose,setPurpose]=useState('Manage Places');
  const [username,setUsername]=useState('');
  const [password,setPassword]=useState('');
  const [isLoggedIn,setLogin]=useState(false);
  const [passwordFailure,setWrongPassword]=useState(false);
  const [isLoading,setLoading]=useState(false);

  const url='https://pakexplore.pk/api/'

  const setTokenHeader=(token)=>{
    if(token){
        axios.defaults.headers.common['Authorization']=`Bearer ${token}`;
    }else{
        delete axios.defaults.headers.common['Authorization'];
    }
  }

  useEffect(()=>{
    if(localStorage.td){
        try{
            setTokenHeader(localStorage.td);
            setLogin(true)
        }catch(er){
            console.log(er)
        }
    }
  },[])

  const handleSubmit=async (e)=>{
    setLoading(true)
    e.preventDefault();
    try{
        const signInObj=await axios.post(url+'signin',{username,password})
        const {token} = signInObj.data
        localStorage.setItem('td', token)
        setTokenHeader(token)
        setLogin(true)
        setUsername('')
        setPassword('')
        setLoading(false)
    }catch(err){
        console.log(err)
        setWrongPassword(true)
        setLoading(false)
    }
  }

  const syncDistricts=()=>{
    axios.post(url+'updatedistricts').then(()=>{
      alert('districts synced')
    }).catch((err)=>{
      console.log(err)
    })
  }

  const loginForm = (
    <div className='admin-main'>
        <div className='login-page'>
            <div className='login-form'>
                <form onSubmit={()=>{}}>
                    <h2>PAKEXPLORE</h2>
                    <h2>Control Panel Login</h2>
                    <input type="text" name='username' value={username} onChange={(e)=>{
                      setUsername(e.target.value)
                      setWrongPassword(false)
                      }} placeholder="username"/>
                    <input type="password" name='password' value={password} onChange={(e)=>{
                      setPassword(e.target.value)
                      setWrongPassword(false)
                      }} placeholder="password"/>
                    <button onClick={handleSubmit} type='submit'>login</button>
                    {isLoading && <div>loading</div>}
                    {passwordFailure && <div className='password-failure-message'>wrong username or password</div>}
                </form>
            </div>
        </div>
    </div>);

  const page=(
    <div>
      <div className='buttons'>
        <button onClick={()=>{setPurpose('Add New Place')}}>Add New Place</button>
        <button onClick={()=>{setPurpose('Manage Places')}}>Manage Places</button>
        <button onClick={()=>{setPurpose('Post Popular Places')}}>Post Popular Places</button>
        <button onClick={()=>{setPurpose('Popular Places')}}>Popular Places</button>
        <button onClick={()=>{syncDistricts()}}>Sync Districts</button>
        <button onClick={()=>{
          localStorage.removeItem('td')
          setTokenHeader(null)
          setLogin(false)
          }}>Logout</button>
      </div>
      {purpose==='Add New Place' && <PostForm url={url} setPurpose={setPurpose}/>}
      {purpose==='Manage Places' && <ControlPanel url={url}/>}
      {purpose==='Post Popular Places' && <PostPopularPlaces url={url} setPurpose={setPurpose}/>}
      {purpose==='Popular Places' && <PopularPlaces url={url}/>}
    </div>);
  return isLoggedIn?page:loginForm
}

export default App;