import React from 'react'
import './destinationCard.css'
import { useNavigate} from 'react-router-dom';

function DestinationCard(props) {
    const [deletePrompt,setDeletePrompt]=React.useState(false)
    const [password,setPassword]=React.useState('');
    const navigate=useNavigate()
    return (
      <div className='card'>
        <img className='img-card' onClick={()=>{
            const url='/'+props.site.province+'/'+props.site.district+'/'+props.site.title
            navigate(url,{state: props.site})
          }} 
          src={props.site.titlePicture} alt=''/>
        <p className='text'>{props.site.title}</p>
        <div className='button-container'>
            <button className='button' onClick={()=>{props.handleModify(props.site)}}>Modify</button>
            <button className='button' onClick={()=>setDeletePrompt(true)}>Delete</button>
        </div>
        <form style={{display: deletePrompt?'block':'none'}} className='form' 
            onSubmit={(e)=>{props.handleDelete(e,props.site.id,password,props.site.province,props.site.district)}}>
            <input onChange={(e)=>{setPassword(e.target.value)}} value={password} type='password' placeholder='enter password'></input>
            <button type='submit'>Go</button>
            <button onClick={(e)=>{
                e.preventDefault();
                setDeletePrompt(false)}}>X</button>
        </form>
      </div>
    );
  }

  export default DestinationCard